//import path from 'path'
import React from 'react'
import Helmet from 'react-helmet'
//import {  graphql } from 'gatsby'
//import PropTypes from 'prop-types'
import SchemaOrg from './SchemaOrg'
//import config from '../../../config/website'

const SEO = ({title,description,keywords,isBlogPost,url }) => {
  
    

      return (
        <React.Fragment>
          
          <Helmet  htmlAttributes={{
           lang: 'de',
             }}>
         
             
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="lang" content="de" />
            <meta name="content-language" content="de" />
            <meta name="robots" content="noindex nofollow noimageindex" />
            {/* <meta name="image" content={image} /> */}

            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            {isBlogPost ? <meta property="og:type" content="article" /> : null}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:keywords" content={keywords} />
            
            {/* <meta property="og:image" content={image} />
            <meta property="fb:app_id" content={seo.social.fbAppID} /> */}

          
          </Helmet>
          <SchemaOrg
            url={url}
            title={title}
           // image={image}
            description={description}
            //author={seo.author}
            //organization={seo.organization}
            defaultTitle={title}
          />
        </React.Fragment>
      )
    }
  





export default SEO




